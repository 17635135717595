// noinspection GraphQLUnresolvedReference

import gql from 'graphql-tag';

const MUTATION = gql`
  mutation AddTagToDossier(
    $dossierId: ID!,
    $dossierTypeSlug: String!,
    $navigationSlug: String!,
    $tagId: ID!
  ) {
    addTagToDossier(
      id: $dossierId,
      dossierTypeSlug: $dossierTypeSlug,
      navigationSlug: $navigationSlug,
      tagId: $tagId
    ) {
      id
      label
      label_color
      label_text_color
    }
  }
`;

export default MUTATION;
